import axios from "axios";
let lastErrorTime = 0;
const ERROR_DEBOUNCE_INTERVAL = 5000;

export async function logErrorToSlack(errorDetails) {
  const currentTime = Date.now();

  // If the time difference since the last error log is less than the debounce interval, don't log
  if (currentTime - lastErrorTime < ERROR_DEBOUNCE_INTERVAL) {
    return;
  }

  lastErrorTime = currentTime; // Update the last error timestamp
  const apiEndpoint = "/system/crash-log"; // Adjust the endpoint as needed.

  try {
    await axios.post(apiEndpoint, {
      error: errorDetails,
    });
    console.info("Error logged successfully.");
  } catch (err) {
    console.error("Failed to log error:", err);
  }
}
